	.blog-item{
		background-color: #fff;
		border-radius: 4px;
		margin-bottom: 40px;
	    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      	-webkit-transition: all 300ms ease-in-out;
      	-moz-transition: all 300ms ease-in-out;
      	transition: all 300ms ease-in-out;
	    .blog-image{
	    	width: 100%;
		    max-width: 370px;
		    position: relative;
        	overflow: hidden;
	        img{
	          background: #000;
	          -webkit-transform: scale(1);
	          -moz-transform: scale(1);
	          -ms-transform: scale(1);
	          -o-transform: scale(1);
	          transform: scale(1);
	          -webkit-transition: all 300ms ease-in-out;
	          -moz-transition: all 300ms ease-in-out;
	          transition: all 300ms ease-in-out;
        	}
	    }
	    .tag{
	    	position: absolute;
		    text-transform: uppercase;
		    top: -18px;
		    left: 20px;
		    padding: 4px 27px;
		    background-color: white;
		    color: $preset;
		    border-radius: 4px;
		    display: block;
		    font-size: 14px;
		    font-weight: 500;
		    box-shadow: 0px 3px 16px rgba(35, 31, 32, 0.15);

	    }
	    .descr{
	    	padding: 30px 15px;
		    color: #212121;
		    position: relative;
		    h3{
		    	line-height: 30px;
		    	margin-bottom: 15px;
		    	a{
			    	font-weight: 400;
				    text-align: center;
				    font-size: 25px;
				    color: #212121;
        			-webkit-transition: all 300ms ease-in-out;
				    -moz-transition: all 300ms ease-in-out;
				    transition: all 300ms ease-in-out;
				    &:hover{
				    	color: $preset;
				    }
		    	}
		    }
        	.meta-tags{
          		margin-bottom: 10px;
          		span{
          			color: #999;
    				margin-right: 8px;
    				a{
    					color: #999;
    					margin-left: 8px;
    					&:hover{
    						color: $preset;
    					}
    				}
          		}
        	}
	    }
	}

  .blog-item:hover{
    box-shadow: 0 0 30px rgba(0,0,0,0.2);
  }
  .blog-item:hover .blog-image img{
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }