// Preset Color Styles
@import "presets";

// Global Styles
@import "global";

// Navbar
@import "navbar";

// About US Area
@import "about";

// Schedule Area
@import "schedule";

// Service Area
@import "service";

// Portfolio Area
@import "portfolio";

// Team Area
@import "team";

// Blog Area
@import "blog";

// Contact Area
@import "contact";

// Contact Area
@import "footer";

// Shortcode
@import "shortcode";


