/* Team Item */
.team-item:hover{
	box-shadow: 0 25px 35px -12px rgba(0,0,0,0.35);
}
.team-item{
	margin: 15px 0;
	padding: 10px;
	border-radius: 4px;
	position: relative;
	box-shadow: 0px 0px 5px rgba(0,0,0,0.17);
	transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
	.team-img{
		position: relative;
		cursor: pointer;
		overflow: hidden;
	}
	.team-overlay{
		height: 100%;
	    position: absolute;
	    top: 0;
	    background: rgba(47, 47, 47, 0.7);
	    opacity: 0;
	    width: 100%;
	    transition: all 0.3s ease-in-out 0s;
	    -moz-transition: all 0.3s ease-in-out 0s;
	    -webkit-transition: all 0.3s ease-in-out 0s;
	    -o-transition: all 0.3s ease-in-out 0s;
	}
	.overlay-social-icon{
	    color: #fff;
	    top: 15px;
	    position: absolute;
	    right: 15px;
		.social-icons{
			padding-left: 0;
			-webkit-transform: translateY(-30px);
			 -moz-transform: translateY(-30px);
			 transform: translateY(-30px);
		    transition: all 0.3s ease-in-out 0s;
		    -moz-transition: all 0.3s ease-in-out 0s;
		    -webkit-transition: all 0.3s ease-in-out 0s;
		    -o-transition: all 0.3s ease-in-out 0s;
		    li{
		    	display: inline-block;
		    	margin: 0 4px;
			    a{
				    letter-spacing: 0px;
				    outline: 0 !important;
				    i{
				    	font-size: 20px;
					    color: #fff;
					    width: 36px;
					    height: 36px;
					    background: $preset;
					    display: block;
					    border-radius: 4px;
					    line-height: 36px;
					    transition: all 0.3s ease-in-out 0s;
					    -moz-transition: all 0.3s ease-in-out 0s;
					    -webkit-transition: all 0.3s ease-in-out 0s;
					    -o-transition: all 0.3s ease-in-out 0s;
					}
			    }
			    a:hover .fa-facebook{
			    	background: #3B5998;
			    }
			    a:hover .fa-twitter{
			    	background: #00aced;
			    }
			    a:hover .fa-instagram{
			    	background: #fb3958;
			    }
		    }
		}
	}
	.info-text{
		position: absolute;
		bottom: 10px;
		left: 10px;
		padding: 15px 20px;
		background: #f3f4f3;
		text-align: left;
	    h3{
	    	font-size: 16px;
		    text-transform: uppercase;
		    font-weight: 700;
		    margin-bottom: 5px;
		    a{
		    	color: #212121;
			    &:hover{
			    	color: $preset;
			    }
		    }
	    }
	    p{
	    	margin: 0;
	    	color: #888;
	    }
	}
}
.team-item:hover .team-overlay{
  opacity: 1;
}
.team-item:hover .social-icons {
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  transform: translateY(0px);
}









