//All Preset color variables are here

 // blue
 $blue: #633DB7;
 $blue-light: #544479;
 
 $primary : $blue;
 $primary-light: $blue-light;
 
//Current Preset Declareation
 $preset:  $blue;