/* Services Item */
.services{
	background: #fff;
}
.padding-none{
	padding: 0;
	border-left: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
}
.padding-none:nth-child(1){
	border-left: none;
}
.padding-none:nth-child(4){
	border-bottom: none;
	border-left: none;
}
.padding-none:nth-child(5){
	border-bottom: none;
}
.padding-none:nth-child(6){
	border-bottom: none;
}
.services-wrapper{
	margin-top: 30px;
}
.services-item{
	padding: 40px 20px;
	text-align: center;
	transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
	.icon{
		i{
			width: 50px;
		    height: 50px;
		    display: inline-block;
		    line-height: 50px;
		    font-size: 48px;
		    text-align: center;
		    margin-bottom: 15px;
		    color: #212121;
		    border-radius: 0;
		    transition: all 0.3s ease-in-out 0s;
		    -moz-transition: all 0.3s ease-in-out 0s;
		    -webkit-transition: all 0.3s ease-in-out 0s;
		    -o-transition: all 0.3s ease-in-out 0s;
		}
	}
	.services-content{
		h3{
			margin-bottom: 10px;
			line-height: 26px;
			a{
				font-size: 20px;
    			letter-spacing: 0.5px;	
    			font-weight: 400;			
    			color: #212121;
			}
		}
	}
}
.services-item:hover{
	background: #f3f3f3;
}
.services-item:hover .icon i{
	background: transparent;
	color: $preset;
}




